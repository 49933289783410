import { useEffect, useState } from "react";
// for work_images
import work1 from "../assets/images/work_images/1.jpg";
import work2 from "../assets/images/work_images/2.jpg";
// works small images
import workSmall1 from "../assets/images/work_images/small/1.jpg";
import workSmall2 from "../assets/images/work_images/small/2.jpg";
// blog post images
import blog1 from "../assets/images/blog_images/1.jpg";
import blog2 from "../assets/images/blog_images/2.jpg";
import blog3 from "../assets/images/blog_images/3.jpg";
import blog4 from "../assets/images/blog_images/4.jpg";
import blog5 from "../assets/images/blog_images/5.jpg";
import blog6 from "../assets/images/blog_images/6.jpg";
// blog image small
import blogSmall1 from "../assets/images/blog_images/small/1.jpg";
import blogSmall2 from "../assets/images/blog_images/small/2.jpg";
import blogSmall3 from "../assets/images/blog_images/small/3.jpg";
import blogSmall4 from "../assets/images/blog_images/small/4.jpg";
import blogSmall5 from "../assets/images/blog_images/small/5.jpg";
import blogSmall6 from "../assets/images/blog_images/small/6.jpg";

import img1 from "../assets/images/slider/brand-1.png";
import img2 from "../assets/images/slider/brand-2.png";
import img3 from "../assets/images/slider/brand-3.png";
import img4 from "../assets/images/slider/brand-4.png";
import img5 from "../assets/images/slider/brand-5.png";
//  icon use as img here
import icon from "../assets/images/icons/icon-1.svg";
import icon1 from "../assets/images/icons/icon-2.svg";
import icon3 from "../assets/images/icons/icon-4.svg";
import icon5 from "../assets/images/icons/icon-6.svg";
import icon7 from "../assets/images/icons/icon-7.svg";
import icon8 from "../assets/images/icons/icon-8.svg";
// contact image
import { AiOutlineHome } from "react-icons/ai";
import { CgNotes } from "react-icons/cg";
import { FaAward, FaRegUser } from "react-icons/fa";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import iconPhone from "../assets/images/contact/phone-call 1.png";

import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";

const AllData = () => {
  const [check] = useState(false);
  const [local] = useState("dark");
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    document.documentElement.classList.add("dark")
  }, []);

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    {
      id: "1",
      tag: "CartX by VorteX",
      title: "Trabajo final de grado",
      img: work1,
      imgSmall: workSmall1,
      bg: "#FFF0F0",
      client: "CartX by VorteX",
      
      link: "https://www.envato.com",
      linkText: "www.envato.com",
      description:`-Descripción general: Trabajando en colaboración con mis compañeros de clase,
        desempeñé un papel clave en el desarrollo de , una solución de pago basada en RFID diseñada
        para supermercados y grandes minoristas <br/><br/>-Aprovechando una pila de tecnología moderna,se integro
        React para las interfaces de cara al cliente y React Native para las interfaces móviles de administración,
        mientras que Electron.js impulsó la interfaz de puerta en una Raspberry Pi 5 para conectarse sin problemas con el hardware RFID y garantizar un procesamiento de datos eficiente.
        En el backend, implementé Node.js, MariaDB y Fastify.<br/><br/>-Contribuciones clave:Lectores RFID integrados con Raspberry Pi 5, que garantizan un flujo de datos fluido 
        y una facturación precisa. <br/><br/>-Desarrollo de interfaz: se creó interfaces intuitivas utilizando React y React Native, lo que permite a los clientes ver artículos, confirmar 
        sus compras y pagar rápidamente. Se creó la interfaz de usuario de la puerta basada en Electron.js para mostrar los detalles del carrito a medida que los usuarios pasan. 
        <br/><br/>-Eficiencia y escalabilidad: Centrado en mejorar los tiempos de respuesta y garantizar la fiabilidad del sistema. Diseñé la arquitectura para adaptarse al crecimiento en el 
        volumen de productos y el tráfico de las tiendas, asegurando transacciones ágiles y confiables en entornos minoristas de alta demanda. 
        <br/> <br/>-Trabajo colaborativo e iterativo: Colaboré estrechamente con mi equipo para refinar constantemente las funcionalidades del sistema. Incorporé retroalimentación de las pruebas 
        realizadas en las tiendas para optimizar la precisión, la rapidez y la facilidad de uso.`,
      proyecto:"TFG"
    },
    {
      id: "2",
      tag: "AlbertoRodriguez",
      title: "Fotografía y diseño",
      img: work2,
      imgSmall: workSmall2,
      bg: "#FFF3FC",
      client: "AlbertoRodríguez",
      
      link: "https://www.themeforest.net",
      linkText: "themeforest.net",
      description:
        `Realice de forma prelaboral trabajos de fotografía con difertenes empresas, personajes públicos y atletas, los cuales me encargue de la fotografía, videografía, edición de los mismos y diseño de recursos gráficos.
        <br/><br/> -Diseño de elementos gráficos : cartelera, logos, banners,elementos de diseño y posters.<br/><br/>-Fotografía : Realización de fotografías y edición de los mismos.<br/><br/>-Videografía:realización de videos 
        y edición de los mismos elementos multimedia<br/><br/>-Herramientas utilizadass:Paquete Adobe: Ilustrator, Photoshop, Lightroom, InDesign y uso de Sony Vegas para edición de video.
    ` ,
   proyecto:"Fotografía y diseño "
      },
  ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
    {
      id: "1",
      img: blog1,
      imgSmall: blogSmall1,
      date: "177 April",
      category: "Inspiration",
      title: "How to Own Your Audience by Creating an Email List.",
      bg: "#FCF4FF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "4",
      img: blog4,
      imgSmall: blogSmall4,
      date: "000 April",
      category: "Inspiration",
      title: "Everything You Need to Know About Web Accessibility.",
      bg: "#EEFBFF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "2",
      img: blog2,
      imgSmall: blogSmall2,
      date: "21 April",
      category: "Web Design",
      title: "The window know to say beside you",
      bg: "#FFF0F0",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "5",
      img: blog5,
      imgSmall: blogSmall5,
      date: "27 April",
      category: "Inspiration",
      title: "Top 10 Toolkits for Deep Learning in 2021.",
      bg: "#FCF4FF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "3",
      img: blog3,
      imgSmall: blogSmall3,
      date: "27 April",
      category: "Inspiration",
      title: "How to Own Your Audience by Creating an Email List.",
      bg: "#FCF4FF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "6",
      img: blog6,
      imgSmall: blogSmall6,
      date: "27 April",
      category: "Inspiration",
      title: "Everything You Need to Know About Web Accessibility.",
      bg: "#EEFBFF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/home/homePage",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/home/about",
      icon: <FaRegUser />,
    },
    {
      id: "06",
      name: "Resume",
      link: "/home/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/home/works",
      icon: <FiCodesandbox />,
    },
    {
      id: "05",
      name: "Contact",
      link: "/home/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "Yo",
      link: "/yo",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "Info.",
      link: "/info",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Trabajos",
      link: "/trabajos",
      icon: <FiCodesandbox />,
    },
    {
      id: "05",
      name: "Contacto",
      link: "/contacto",
      icon: <RiContactsBookLine />,
    },
  ];

  // Slider image for Clients
  const sliderImg = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img1,
    img2,
    img3,
    img4,
    img5,
  ];

  // experience items for about page
  const experienceArray = [
    {
      id: "1",
      icon: icon,
      title: "Manipulación de datos",
      des: "Conocimientos en manipulación y analisis de datos.",
      color: "#D566FF",
      bg: "#FCF4FF",
    },
    {
      id: "2",
      icon: icon1,
      title: "Entornos de trabajo",
      des: "Conocimientos en el uso de ERP Y herramientas de colaboración.  ",
      color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: "3",
      icon: icon8,
      title: "Desarrollo de interfaces",
      des: "Creacíon de interfaces y diseño.",
      color: "#8774FF",
      bg: "#FCF4FF",
    },
    {
      id: "4",
      icon: icon3,
      title: "Fotografía y Edición",
      des: "Conocimientos en fotografía con trabajos prelaborales y edicíon.",
      color: "#FF6080",
      bg: "#FFF4F4",
    },
    {
      id: "5",
      icon: icon5,
      title: "Programación multiplataforma",
      des: "Conocimientos en programación de aplicaciones y web",
      color: "#FF75D8",
      bg: "#FFF0F8",
    },
    {
      id: "6",
      icon: icon7,
      title: "Marketing y comercio",
      des: "Conocimientos en el desarrollo de acciones de marketing y ventas. ",
      color: "#269FFF",
      bg: "#F3FAFF",
    },
  ];

  // Resume items for Resume page
  const resumeArray = [
    {
      type: "Educación",
      icon: MdOutlineSchool,
      items: [
        {
          date: "2023-Actual",
          title: "Desarrollo de Aplicaciones Multiplataforma",
          place: "MEDAC",
          bg: "#FFF4F4",
        },
        {
          date: "2023- Actual",
          title: "Big Data e IA",
          place: "MEDAC",
          bg: "#FFF1FB",
        },
        {
          date: "2022-2023",
          title: "Técnico en Big Data ",
          place: "CCC",
          bg: "#FFF4F4",
        },
        {
          date: "Septiembre - Octubre 2024",
          title: "Introducción Big Data ",
          place: "Adecco",
          bg: "#FFF4F4",
        },
        {
          date: "2021-2023",
          title: "Comercio y Marketing ",
          place: "C.C Lope de Vega",
          bg: "#FFF4F4",
        },
      ],
    },
    {
      type: "Experiencia",
      icon: MdOutlineBusinessCenter,
      items: [
        {
          date: "Febrero - Junio 2023",
          title: "Marketing y ventas",
          place: "Oh my Cut",
          bg: "#EEF5FA",
        },
        {
          date: "Junio 2022 - Enero 2023",
          title: "Diseño web y Responsable de Informática",
          place: "Crompatuled - Optima",
          bg: "#EEF5FA",
        },
        {
          date: "Septiembre - Octubre 2023",
          title: "Azafato de eventos",
          place: "I.F.A",
          bg: "#EEF5FA",
        },
        {
          date: "Junio - Septiembre 2024",
          title: "Cajero",
          place: "Carrefour",
          bg: "#EEF5FA",
        },
        {
          date: "Septiembre 2021 - Junio 2024",
          title: "Camarero (local y eventos)",
          place: "Monumental / Pico Fino / 100 Mont.",
          bg: "#EEF5FA",
        },
      ],
    },
    {
      type: "Awards",
      icon: FaAward,
      items: [
        {
          date: "2015-2017",
          title: "  Graphic Designer",
          place: "Web Graphy, Los Angeles, CA",
          bg: "#FCF4FF",
        },
      ],
    },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#FF6464",
      name: "Manipulación de datos",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "Programación de aplicaciones / webs ",
    },
    {
      id: "03",
      color: "#5185D4",
      name: "Entornos de trabajo",
    },
    {
      id: "04",
      color: "#CA56F2",
      name: "Desarrollo de interfaces",
    },
    {
      id: "06",
      color: "#CA56F2",
      name: "Diseño gráfico",
    },
    {
      id: "06",
      color: "#CA56F2",
      name: "Atención al cliente",
    },
    {
      id: "06",
      color: "#CA56F2",
      name: "Comercio",
    },
    {
      id: "06",
      color: "#CA56F2",
      name: "Fotografía",
    },
    {
      id: "06",
      color: "#CA56F2",
      name: "Marketing",
    },
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "Teléfono ",
      item1: "+452 666 386",
      item2: "+452 666 386",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "support@gmail.com",
      item2: "example@gmail.com",
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: iconMap,
      title: "Dirección ",
      item1: "Maount View, Oval",
      item2: "Road, New York, USA",
      bg: "#F2F4FF",
    },
  ];

  return {
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    menuItemTwo,
    experienceArray,
    sliderImg,
    resumeArray,
    lineArray,
    contactArray,
  };
};

export default AllData;
