import React from 'react';
import UseData from '../../Hooks/UseData';

const LineItem = ({item}) => {
  const {local} = UseData()
    return (
        <div className=" mb-3">
        <div className="flex justify-between py-1">
          <span className=" text-base text-gray-lite font-semibold dark:text-[#A6A6A6]">
            {item?.name}
          </span>
        </div>
      </div>
    );
};

export default LineItem;